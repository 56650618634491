import firebase from 'firebase';

var config = {
    apiKey: "AIzaSyB0SNVpGvp5WJ_AalpvL00gkgSOh_-lhXU",
    authDomain: "house-points-4935e.firebaseapp.com",
    databaseURL: "https://house-points-4935e-default-rtdb.firebaseio.com",
    // projectId: "house-points-4935e",
    // storageBucket: "house-points-4935e.appspot.com",
    // messagingSenderId: "566737491510",
    // appId: "1:566737491510:web:adfeab03df61a8bcd42a97"
};

firebase.initializeApp(config);
export const auth = firebase.auth;
export const db = firebase.database();