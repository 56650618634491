import React, { Component } from 'react';

import {
  Route,
  BrowserRouter as Router,
  Switch,
  Redirect
} from "react-router-dom";

import Home from '../../pages/Home';
import Leaderboard from '../../pages/Leaderboard';
import AwardPoints from '../../pages/AwardPoints';
import Login from '../../pages/Login';

import { auth } from "../../services/firebase";

import './App.scss';

import HeaderBar from '../../components/HeaderBar/HeaderBar';

function PrivateRoute({ component: Component, authenticated, ...rest }) {
  return (
    <Route
      {...rest}
      render={props =>
        authenticated === true ? (
          <Component {...props} />
        ) : (
            <Redirect
              to={{ pathname: "/login", state: { from: props.location } }}
            />
          )
      }
    />
  );
}

function PublicRoute({ component: Component, authenticated, ...rest }) {
    return (
      <Route
        {...rest}
        render={props =>
          authenticated === false ? (
            <Component {...props} />
          ) : (
              <Redirect to="/home" />
            )
        }
      />
    );
}

class App extends React.Component {

  constructor(props) {
    super();
    this.state = {
      authenticated: false,
      loading: true
    };

    this.logout = this.logout.bind(this);
  }

  componentDidMount() {
    auth().onAuthStateChanged(user => {
      if (user) {
        this.setState({
          authenticated: true,
          loading: false
        });
      } else {
        this.setState({
          authenticated: false,
          loading: false
        });
      }
    });
  }

  logout() {
    auth.signOut()
      .then(() => {
        this.setState({
          user: null
        });
      });
  }

  render(){
    return this.state.loading === true ? (
      <div className="container mt-4">
        <div>Loading...</div>
      </div>
    ) : (
      <div>
        <Router>
          <HeaderBar></HeaderBar>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/home" component={Home} />
            <Route exact path="/leaderboard/:yearId" component={Leaderboard} />
            <PrivateRoute
              path="/awardpoints"
              authenticated={this.state.authenticated}
              component={AwardPoints}
            />
            <PublicRoute
              path="/login"
              authenticated={this.state.authenticated}
              component={Login}
            />
          </Switch>
        </Router>
      </div>
    );
  }
}

export default App;
