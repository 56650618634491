
export function yearIdToIndex(yearId) {
  switch(yearId){
    case "gamedesignanddevelopment1": return 0;
    case "gamedesignanddevelopment2": return 1;
    case "gamedesignanddevelopment3": return 2;
    case "digitaldesign3": return 3;
    case "lecturerorientation": return 4;
  }
}

export function yearIndexToName(index) {
  switch(index){
    case 0: return "Game Design & Development 1";
    case 1: return "Game Design & Development 2";
    case 2: return "Game Design & Development 3";
    case 3: return "Digital Design 3";
    case 4: return "Lecturer Orientation";
  }
}

export function yearIdToName(yearId) {
  switch(yearId){
    case "gamedesignanddevelopment1": return "Game Design & Development 1";
    case "gamedesignanddevelopment2": return "Game Design & Development 2";
    case "gamedesignanddevelopment3": return "Game Design & Development 3";
    case "digitaldesign3": return "Digital Design 3";
    case "lecturerorientation": return "Lecturer Orientation";
  }
}

export function yearIdToHumanText(yearId){
  switch(yearId){
      case "gamedesignanddevelopment1": return "Game Design & Development 1";
      case "gamedesignanddevelopment2": return "Game Design & Development 2";
      case "gamedesignanddevelopment3": return "Game Design & Development 3";
      case "digitaldesign3": return "Digital Design 3";
      case "lecturerorientation": return "Lecturer Orientation";
      case "nav": return "Not Applicable";
  }
}

export function houseIndexToName(index){
  switch(index){
    case 0: return "Gryffindor";
    case 1: return "Hufflepuff";
    case 2: return "Ravenclaw";
    case 3: return "Slytherin";
  }
}

export function timeSince(timeStamp) {
  let now = new Date();
  timeStamp = new Date(timeStamp);
  let secondsPast = (now.getTime() - timeStamp) / 1000;

  if (secondsPast < 60) {
    return parseInt(secondsPast) + 's ago';
  }
  if (secondsPast < 3600) {
    return parseInt(secondsPast / 60) + 'm ago';
  }
  if (secondsPast <= 86400) {
    return parseInt(secondsPast / 3600) + 'h ago';
  }
  if (secondsPast > 86400) {
    let day = timeStamp.getDate();
    let month = timeStamp.toDateString().match(/ [a-zA-Z]*/)[0].replace(" ", "");
    let year = timeStamp.getFullYear() == now.getFullYear() ? "" : " " + timeStamp.getFullYear();
    return day + " " + month + year;
  }
}