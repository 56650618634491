import React from 'react';
import './HouseView.scss';

class HouseView extends React.Component {
  render(){
    console.log(this.props)
    const minWidth = 2;

    let width;
    if(this.props.highestPoints == 0)
      width = minWidth;
    else
      width = this.props.housePoints / this.props.highestPoints * (100 - minWidth) + minWidth;

    const barStyle = {
      width: width + "%"
    }

    console.log(barStyle);

    return (
      <div className="HouseView">
        <div className={`Bar ${this.props.houseName} darken`} style={barStyle}>
          <h2 className="Points"><b>{this.props.housePoints}</b></h2>
          <h4 className="Name">{this.props.houseName}</h4>
          <img src={`/${this.props.houseName.toLowerCase()}.png`} alt="" />
        </div>
      </div>
    );
  }
}

export default HouseView;
