import React from 'react';

import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';

import { auth } from '../../services/firebase';
import { logout } from '../../helpers/auth';

import { Link, withRouter } from 'react-router-dom';

import './HeaderBar.scss';

class HeaderBar extends React.Component {
  constructor(props) {
      super(props);
      this.state = {
          user: auth().currentUser
      };
      this.handleLogout = this.handleLogout.bind(this);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
        this.setState({user: auth().currentUser})
    }
  }

  handleLogout(){
    this.setState({user: null});
    logout();
  }

  render(){

    return (
      <header className="HeaderBar">
        <Navbar bg="dark" variant="dark" expand="lg">
          <div className="container">
            <Navbar.Brand as={Link} to="/">House Points</Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="mr-auto">
                <Nav.Link as={Link} to="/">Home</Nav.Link>
                <NavDropdown title="Leaderboards" id="basic-nav-dropdown" className="dropdown-menu-dark">
                  <NavDropdown.Item as={Link} to="/leaderboard/gamedesignanddevelopment1">Game Design &amp; Development 1</NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/leaderboard/gamedesignanddevelopment2">Game Design &amp; Development 2</NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/leaderboard/gamedesignanddevelopment3">Game Design &amp; Development 3</NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/leaderboard/digitaldesign3">Digital Design 3</NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/leaderboard/lecturerorientation">Lecturer Orientation</NavDropdown.Item>
                </NavDropdown>
              </Nav>
              {this.state.user ? (
                <Nav>
                  <Nav.Link as={Link} to="/awardpoints">Award Points</Nav.Link>
                  <Nav.Link href="#" onClick={this.handleLogout}> Logout </Nav.Link>
                </Nav>
              ) : (
                <Nav>
                  <Nav.Link as={Link} to="/login"> Log in </Nav.Link>
                </Nav>
              )}
            </Navbar.Collapse>
            
          </div>
        </Navbar>
      </header>
    )
  }
}

export default withRouter(HeaderBar);