import React, { Component } from "react";

import { auth } from "../services/firebase";
import { db } from "../services/firebase";
import { yearIdToIndex, yearIdToName, houseIndexToName } from "../helpers/utils";

import HouseList from '../components/HouseList/HouseList';
import '../scss/Leaderboard.scss';

export default class Leaderboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: auth().currentUser,
            years: [],
            highestScore: 0,
            dataLoaded: false,
            readError: null,
            writeError: null
        };
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.match.params.yearId !== this.props.match.params.yearId) {
            this.loadYearData(nextProps.match.params.yearId);
        }
    }

    componentDidMount() {
        this.loadYearData(this.props.match.params.yearId);
    }

    async loadYearData(yearId){
        console.log("yearId", yearId)
        this.setState({ readError: null, yearId: yearId});
        try {
            console.log("begin loading year points")
            db.ref("years").on("value", snapshot => {
                console.log(snapshot);
                let years = [];
                snapshot.forEach((snap) => {
                    let year = snap.val();
                    years.push(year);
                });
                this.setState({ dataLoaded: true, years: years });
            });
        } catch (error) {
            this.setState({ readError: error.message });
            console.error(error.message);
        }
    }

    renderHouses(){
        if(this.state.dataLoaded){
            return (
                <section> 
                    <h1><b>{yearIdToName(this.state.yearId)}</b></h1>
                    <h4>{this.getLeadingHouseText()}</h4>
                    <hr className="page-heading"/>
                    <HouseList 
                        points={this.getYear().points} 
                        year={yearIdToName(this.state.yearId)}
                        highestPoints={this.getHighestPoints()}
                    ></HouseList>
                </section>
            )
        }else{
            return <div>Loading...</div>
        }
    }

    getHighestPoints(){
        let highest = -1;
        this.getYear().points.forEach((housePoints) => {
            if(housePoints > highest){
                highest = housePoints;
            }
        });
        console.log("Highest: ", highest)
        return highest;
    }

    getLeadingHouseText(){
        let highest = this.getHighestPoints();
        let topHouses = [];
        this.getYear().points.forEach((housePoints, index) => {
            if(housePoints == highest){
                topHouses.push(index);
            }
        });

        if(topHouses.length == 1){
            let houseName = houseIndexToName(topHouses[0]);
            return houseName + " is in the lead."
        }else{
            let houseName = "";
            for(let i = 0; i < topHouses.length; i++){
                houseName += houseIndexToName(topHouses[i]);

                if(i < topHouses.length-2){
                    houseName += ", ";
                }else if(i == topHouses.length-2){
                    houseName += " and ";
                }
            }
            return houseName + " are tied for the lead."
        }
        return "";
    }

    getYear(){
        return this.state.years[yearIdToIndex(this.state.yearId)];
    }

    render() {
        console.log(this.state);
    
        return (
            <div className="Leaderboard">
                <div className="container pt-4 pb-5">
                    <div className="wrapper">
                        {this.renderHouses()}
                    </div>
                </div>
            </div>
        );
    }
}
