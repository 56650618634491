import React from 'react';
import './PointsRemaining.scss';

class PointsRemaining extends React.Component {
  render(){

    let remaining = (this.props.pointsCap - this.props.pointsUsed);

    let width =  remaining / this.props.pointsCap * 100;
    let colourClass = "green";

    if(width > 25 && width < 50){
      colourClass = "orange";
    }else if(width <= 25){
      colourClass = "red";
    }

    const barStyle = {
      width: width + "%",
    }

    console.log(barStyle);

    return (
      <div className="PointsRemaining">
        <div className="bar-wrapper">
          <div className={colourClass + " bar"} style={barStyle}></div>
        </div>
        <p className="small textRemaining">{remaining} / {this.props.pointsCap}</p>
      </div>
    );
  }
}

export default PointsRemaining;
