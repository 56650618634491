import React, { Component } from "react";
import { Link } from "react-router-dom";
import { signin } from "../helpers/auth";

export default class Login extends Component {
    constructor() {
        super();
        this.state = {
            error: null,
            email: "",
            password: ""
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    async handleSubmit(event) {
        event.preventDefault();
        this.setState({ error: "" });
        try {
            await signin(this.state.email, this.state.password);
        } catch (error) {
            this.setState({ error: error.message });
        }
    }

    render() {
        return (
            <div className="container mt-4">
                <h1><b>Login</b></h1>
                <h4>Fill in the form below to login to your account.</h4>
                <hr className="page-heading"/>
                <form className="" autoComplete="off" onSubmit={this.handleSubmit} >
                <div className="form-group">
                    <input
                        className="form-control"
                        placeholder="Email"
                        name="email"
                        type="email"
                        onChange={this.handleChange}
                        value={this.state.email}
                    />
                </div>
                <div className="form-group">
                    <input
                        className="form-control"
                        placeholder="Password"
                        name="password"
                        onChange={this.handleChange}
                        value={this.state.password}
                        type="password"
                    />
                </div>
                <div className="form-group">
                    {this.state.error ? (
                    <p className="text-danger">{this.state.error}</p>
                    ) : null}
                    <button className="btn btn-dark px-5" type="submit">Login</button>
                </div>
                </form>

            </div>
        );
    }
}
