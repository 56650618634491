import React, { Component } from 'react';

import { auth } from "../services/firebase";
import { db } from "../services/firebase";

import { Link } from "react-router-dom";

import PointsRemaining from '../components/PointsRemaining/PointsRemaining';

export default class AwardPoints extends Component {

    constructor() {
        super();
        this.state = {
            user: auth().currentUser,
            readError: null,
            writeError: null,
            years: [],
            pointsAwarded: -999999,
            houseAwarded: -1,
            yearAwarded: -1,
            savingState: "save",
            dataLoaded: false
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.match.params.yearId !== this.props.match.params.yearId) {
            this.loadYearData(nextProps.match.params.yearId);
        }
    }

    componentDidMount() {
        this.loadYearData(this.props.match.params.yearId);
    }

    async loadYearData(yearId){
        console.log("yearId", yearId)
        this.setState({ readError: null, yearId: yearId});
        try {
            console.log("begin loading year points")
            db.ref("years").on("value", snapshot => {
                console.log(snapshot);
                let years = [];
                snapshot.forEach((snap) => {
                    let year = snap.val();
                    years.push(year);
                });
                this.setState({ dataLoaded: true, years: years });
                console.log(this.state.years);
            });
        } catch (error) {
            this.setState({ readError: error.message });
            console.error(error.message);
        }
    }

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    async handleSubmit(event) {
        event.preventDefault();
        console.log("submitting...");

        console.log(this.state.years);

        this.state
        .years[this.state.yearAwarded]
        .points[this.state.houseAwarded] += parseInt(this.state.pointsAwarded);

        this.setState({ writeError: null });
        this.setState({ savingState: "saving"});

        try {
            let data = this.state.years;
            console.log(data);

            db.ref("years").update(data, error => {
                console.error(error);
            });
            console.log("db updated");

            this.setState({ 
                savingState: "saved"
            });

            setTimeout(() => {
                this.setState({ savingState: "save"});
            }, 1000);
        } catch (error) {
            console.error(error);
            this.setState({ writeError: error.message });
        }
    } 

    renderSaveButton(){
        if(this.state.savingState == "saved"){
           return <button className="btn btn-dark px-5" disabled type="submit">Saved!</button>
        }else if(this.state.savingState == "saving"){
           return <button className="btn btn-dark px-5" disabled type="submit">Saving...</button>
        }

        return <button className="btn btn-dark px-5" disabled={!this.isFormValid()} type="submit">Save</button>
    }

    renderPointsOptions(){
        return this.state.pointOptions.map((points, index) => (
            <option key={index} value={points}>
                {points + " points"}
            </option>
        ));
    }

    renderPage(){
        return this.renderForm();
    }

    isFormValid(){
        let isValid = true;

        if(this.state.pointsAwarded == -999999){
            isValid = false;
        }

        if(this.state.houseAwarded == -1){
            isValid = false;
        }

        if(this.state.yearAwarded == -1){
            isValid = false;
        }

        return isValid;
    }

    renderForm(){
        return <form className="" onSubmit={this.handleSubmit}>

            <div className="form-group">
                <label htmlFor="yearAwarded">What year?</label>
                <select className="form-control" value={this.state.yearAwarded} name="yearAwarded" onChange={this.handleChange}>
                    <option value="-1">Please select and option</option>
                    <option value="0">Game Design &amp; Development 1</option>
                    <option value="1">Game Design &amp; Development 2</option>
                    <option value="2">Game Design &amp; Development 3</option>
                    <option value="3">Digital Design 3</option>
                    <option value="4">Lecturer Orientation</option>
                </select>
            </div>

            <div className="form-group">
                <label htmlFor="houseAwarded">Which house?</label>
                <select className="form-control" value={this.state.houseAwarded} name="houseAwarded" onChange={this.handleChange}>
                    <option value="-1">Please select and option</option>
                    <option value="0">Griffyndor</option>
                    <option value="1">Hufflepuff</option>
                    <option value="2">Ravenclaw</option>
                    <option value="3">Slytherin</option>
                </select>
            </div>

            <div className="form-group">
                <label htmlFor="pointsAwarded">How many points?</label>
                <select className="form-control" value={this.state.pointsAwarded} name="pointsAwarded" onChange={this.handleChange}>
                    <option value="0">Please select and option</option>
                    <option value="5">5 points</option>
                    <option value="10">10 points</option>
                    <option value="20">20 points</option>
                    <option value="30">30 points</option>
                    <option value="50">50 points</option>
                    <option value="100">100 points</option>
                    <option value="-5">-5 points</option>
                    <option value="-10">-10 points</option>
                </select>
            </div>

            <div className="form-group">
                {this.state.error ? <p className="">{this.state.error}</p> : null}
                {this.renderSaveButton()}
            </div>
        </form>
    }

    render() {
        return (
            <div className="AwardPoints container mt-4">
                <h1><b>Award Points</b></h1>
                <h4>Select the year and house, and the number of points being awarded.</h4>
                <hr className="page-heading"/>
                {this.renderPage()}
            </div>
        )
    }
}