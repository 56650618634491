import React from 'react';
import './HouseList.scss';
import HouseView from '../HouseView/HouseView';
import { houseIndexToName } from "../../helpers/utils";

class HouseList extends React.Component {
  render(){
    
    const housesList = this.props.points.map((housePoints, index) => 
      <li key={index}>
        <HouseView 
          houseName={houseIndexToName(index)} 
          housePoints={housePoints} 
          highestPoints={this.props.highestPoints}
        ></HouseView>
      </li>
    );
    
    return (
      <div className="HouseList">
        <ul>{housesList}</ul>
      </div>
    );
  }
}

export default HouseList;
